// https://select2.org/programmatic-control/events

import $ from 'jquery'
import 'select2'
import cs from 'select2/src/js/select2/i18n/cs'

import { elementExists } from '../shared'
import { selectors } from '../selectors'

export const NATIONALITY_ID = {
    CZECH: '1',
    SLOVAK: '2',
    OTHER: '100',
}

export const ID_CARD_PLACEHOLDER = {
    [NATIONALITY_ID.CZECH]: '100200300',
    [NATIONALITY_ID.SLOVAK]: 'AB100200',
    [NATIONALITY_ID.OTHER]: 'XZ100200',
}

export const COUNTRY_CODE = {
    [NATIONALITY_ID.CZECH]: '+420',
    [NATIONALITY_ID.SLOVAK]: '+421',
    [NATIONALITY_ID.OTHER]: '',
}

export const PHONE_NUMBER_PREFIX = {
    [NATIONALITY_ID.CZECH]: '^[367].*$',
    [NATIONALITY_ID.SLOVAK]: '^9.*$',
    [NATIONALITY_ID.OTHER]: '^[^3679].*$',
}

export const init = () => {
    initFor(selectors.inputs.nationality) // RegistrationClassic
}

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $input = $(selector)

    $input.select2({
        language: cs,
        placeholder: 'Státní občanství',
        width: 'resolve',
        minimumResultsForSearch: -1, // Hides default <input search in dropdown. Don't need 'em if only 3 results to pick.
    })

    $input.on('select2:open select2:select', () => {
        $input.parents('label').find('.select2-selection').addClass('active')
    })
    $input.on('select2:close', () => {
        !$input._isValid() && $input.parents('label').find('.select2-selection').removeClass('active')
    })
}
