import $ from 'jquery'
import { selectors } from '../selectors'
import { $elementExists } from '../shared'

const SIDE_NAV_WIDTH = 280
const $sideNav = $(selectors.content.sideNavOld)
const $menuBtn = $(selectors.buttons.menuBtn)
const $closeMenuBtn = $(selectors.buttons.closeMenuBtn)
const $clientSettingsButton = $(selectors.buttons.clientSettingsButton)
const $clientSettingsMenu = $(selectors.content.clientSettingsMenu)
const $clientSettingsButtonRow = $(selectors.buttons.clientSettingsButtonRow)
const $clientSettingsMenuRow = $(selectors.content.clientSettingsMenuRow)

// -------------------- Menu --------------------

export function initMenu() {
    // Init toggle
    $elementExists($menuBtn) && $menuBtn.click(() => toggleNav())
    $elementExists($clientSettingsButton) && $clientSettingsButton.click(() => toggleSettings())
    $elementExists($clientSettingsButtonRow) && $clientSettingsButtonRow.click(() => toggleSettingsRow())

    // Init outside click
    $('document').on('click', 'body *', () => hideNav())
    $('document').on('click', 'body *', () => hideSettings())
    $('document').on('click', 'body *', () => hideSettingsRow())

    // Init x button click
    $elementExists($closeMenuBtn) && $closeMenuBtn.click(() => toggleNav())
}

const hideNav = () => $sideNav.width(0)

const showNav = () => $sideNav.width(SIDE_NAV_WIDTH)

const toggleNav = () => {
    $sideNav.width() === SIDE_NAV_WIDTH && hideNav()
    $sideNav.width() === 0 && showNav()
}

// -------------------- Menu Button --------------------

export const hideMenuButton = () => $(selectors.buttons.menuBtn).hide()

export const showMenuButton = () => $(selectors.buttons.menuBtn).show()

export const toggleMenuButton = (hide = true) => {
    hide && hideMenuButton()
    !hide && showMenuButton()
}

// -------------------- Client Settings --------------------

const hideSettings = () => {
    $clientSettingsMenu.removeClass('show-settings')
}

const toggleSettings = () => {
    $clientSettingsMenu.toggleClass('show-settings')
}

const hideSettingsRow = () => {
    $clientSettingsMenuRow.removeClass('show-settings')
}

const toggleSettingsRow = () => {
    $clientSettingsMenuRow.toggleClass('show-settings')
}

export const hideSettingsButton = () => $(selectors.content.regisrationHiddenElements).hide()
