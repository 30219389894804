import $ from 'jquery'
import _ from 'lodash'

import { $buttonSpinnerFinnish, $buttonSpinnerStart, $feedbackSpinnerFinnish, $feedbackSpinnerStart, ATTR, fetchCache, setCache } from './helpers'
import { unBindConfirmPageLeaving } from '../registration/windowEvents'
import { config } from '../config'
import { handlePinCodeValidationResponse } from '../form/pincode'

/**
 * resolve(null) -> skip
 * resolve(true) -> success
 * resolve(message) -> success with custom feedback
 * reject() -> failure
 * reject(message) -> failure with custom feedback
 */

let abortControllers = []

const abort = (selector) => {
    abortControllers[selector]?.abort()
    abortControllers[selector] = new AbortController()
}

const signal = (selector) => abortControllers[selector].signal

// ----------------------------------------------------

const extendedFetch = async (...args) => {
    const [url, options] = args
    const { selector, value } = options
    const $input = $(selector)

    abort(selector)
    $feedbackSpinnerStart($input)

    // prettier-ignore
    const result = await Promise.any([
        fetchCache($input, value),
        fetch(url, { ...options, signal: signal(selector) }).then((response) => response.json()),
    ])
      .then((data) => setCache($input, value, data))

    $feedbackSpinnerFinnish($input)

    return result
}

// ----------------------------------------------------

export const identificationNumber = ({ selector }, { resolve, reject }) => {
    // 6905180183
    const MESSAGES = {
        'registration-in-process': 'Máte již rozpracovanou jinou žádost, kontaktujte nás <br />na tel: <a href="' + config.companyPhoneNumberHref + '" style="color: white; text-decoration: underline;">' + config.companyPhoneNumber + '</a>',
        'customer-exists': 'Již jste naším klientem - <a href="/klient/login" style="color: white; text-decoration: underline;">přihlaste se</a>',
        'invalid-format': 'Rodné číslo má nesprávný tvar!',
    }

    const $input = $(selector)
    const nin = _.trim($input.val().replace(/\//, ''))

    extendedFetch(ninVerificationUrl, {
        selector: selector,
        value: nin,
        method: 'POST',
        body: JSON.stringify({ nin }),
    })
        .then(({ isValid, message }) => {
            isValid ? resolve(true) : reject(MESSAGES[message])
            ;['registration-in-process', 'customer-exists'].includes(message) && unBindConfirmPageLeaving()
        })
        .catch((e) => console.warn(e) || (!(e instanceof AggregateError) && reject('Server error.')))
}

// eslint-disable-next-line
export const address = ({ selector }, { resolve, reject }) => {
    // Done by SmartForm...

    resolve(true)
}

export const validatePinCode = async (selector) => {
    if (!selector) return
    const $pinCodeInput = $(selector)
    const pinCode = $pinCodeInput.val()
    const form = $pinCodeInput.closest('form')
    const submitButton = form.find('button[type="submit"]')

    $pinCodeInput.on('focus', () => $(selector)._forceSuccess(ATTR.VALID._EXTERNAL, true))

    $buttonSpinnerStart(submitButton)

    await fetch(validatePincodeUrl, {
        method: 'POST',
        body: JSON.stringify({ pinCode }),
    })
        .then((res) => res.json())
        .then((data) => handlePinCodeValidationResponse(data, selector))
        .then((submitForm) => {
            submitForm && form.submit()
        })
        .catch((err) => console.warn(err))

    $buttonSpinnerFinnish(submitButton)
}
