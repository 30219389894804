import $ from 'jquery'
import { selectors } from '../selectors'
import { ATTR } from '../validation/helpers'

const MESSAGES = {
    ERROR: 'SMS kód není platný.',
}

const RESPONSE_STATUS_VALIDATION = {
    ERROR: 'error',
    SUCCESS: 'success',
}

const RESPONSE_STATUS_RESEND = {
    ATTEMPTS_EXCEEDED: -1,
    SUCCESS: 1,
}

export const handlePinCodeValidationResponse = (data, inputSelector) => {
    let submitForm
    if (data.status === RESPONSE_STATUS_VALIDATION.ERROR) {
        $(inputSelector)._forceError(ATTR.VALID._EXTERNAL, MESSAGES.ERROR)

        submitForm = false
    }
    if (data.status === RESPONSE_STATUS_VALIDATION.SUCCESS) {
        submitForm = true
    }

    return submitForm
}

export const handlePinCodeResendResponse = ({ message, status }) => {
    const $resendDiv = $(selectors.content.resendPinDiv)
    $resendDiv.append(`<p class="resend-pincode__message"> ${message} </p>`)

    if (status === RESPONSE_STATUS_RESEND.SUCCESS) {
        setTimeout(() => {
            $(selectors.content.resendPincodeMessage).hide()
        }, 5000)
    }

    if (status === RESPONSE_STATUS_RESEND.ATTEMPTS_EXCEEDED) {
        $(selectors.buttons.resendPinCode).unbind('click')
    }
}
